<!-- 提现 -->
<template>
  <div id="withdraw" class="container">
    <van-nav-bar 
      :title="$t('withdrawal.tabIndex1')"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 头部 -->
    <div class="head">
      <p class="tit">{{ $t("withdrawal.ktxye") }}</p>
      <!-- <p class="money" @click="moneyShow()">{{ ktmoney | privatefg }}</p> -->
     <p class="money" @click="moneyShow()">{{ ktmoney | privateMoney }}</p>
    	<div class="heads" v-if="show == true">
    		<!-- <p class="moneys">{{ ktmoney | privatefg }}</p> -->
    		<p class="moneys">{{ ktmoney }}</p>
    	</div>
    </div>
    <!-- 银行或者币链 -->
    <van-cell v-if="checkeZh" class="top-cell" @click="showSkFun" is-link>
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <div class="cell-title">
          <div class="icon_warp" v-if="checkeZh.rowtype == 2">
            <img
              v-if="checkeZh && checkeZh.type == 0"
              src="../../../assets/b_img/BTC.png"
              alt=""
            />
            <img
              v-else-if="checkeZh && checkeZh.type == 1"
              src="../../../assets/b_img/USDT.png"
              alt=""
            />
            <img
              v-else-if="checkeZh && checkeZh.type == 2"
              src="../../../assets/b_img/ETH.png"
              alt=""
            />
            <img
              v-else-if="checkeZh && checkeZh.type == 3"
              src="../../../assets/b_img/LTC.png"
              alt=""
            />
            <img
              v-else-if="checkeZh && checkeZh.type == 4"
              src="../../../assets/b_img/USDC.png"
              alt=""
            />
            <i v-else class="iconfont icon-yingbi"></i>
          </div>
          <div class="info">
            <span class="custom-title"> {{ checkeZh.name | nameFormet }}</span>
            <span class="custom-title-bot" v-if="checkeZh.rowtype == 2">
              {{ checkeZh.address }}
            </span>
            <span class="custom-title-bot" v-else>
              {{ $t("withdrawal.h4w") }}:{{ checkeZh.bank_card | cardFormet }}
            </span>
          </div>
        </div>
      </template>
    </van-cell>
    <!-- 信息 -->
    <van-cell-group>
      <van-cell :title="$t('withdrawal.txje')" />
      <van-field
        v-model="money"
        type="number"
        :placeholder="$t('withdrawal.txjemsg')"
      />
    </van-cell-group>
    <van-cell-group>
      <van-field
        v-model="pay_password"
        type="password"
        maxlength="4"
        :label="$t('withdrawal.txmm')"
        :placeholder="$t('withdrawal.qsrtxmm')"
      />
    </van-cell-group>
    <!-- 提现按钮 -->
    <div class="btn_warp">
      <van-button class="txbtn" @click="withdrawDoFun" type="default">{{
        $t("withdrawal.tabIndex1")
      }}</van-button>
    </div>
    <!-- 底部提示信息 -->
    <div class="description" style="margin: 0px 0.262rem">
      <div class="rule-tit">
        <i class="iconfont icontishi1"></i>{{ $t("common.wxMsg") }}:
      </div>
      <div class="rule-info">
        <p></p>
        <p>
          {{ $t("withdrawal.msgb")
          }}<a href="/service" class="">{{ $t("m_home.lxkf") }}</a>
        </p>
      </div>
    </div>
    <p class="with_tips bankInfo" @click="showSkFun">
      {{ $t("withdrawal.ckskzh") }}
    </p>
    <!-- 选择类型弹窗 -->
    <addTypePopup @close="close" @change="change" v-if="showPopup" />
    <!-- 查看我的收款账户 -->
    <van-popup
      class="popup-sk"
      v-model="skShow"
      position="bottom"
      closeable
      close-icon-position="top-right"
      :style="{ height: '100%' }"
    >
      <!-- 头部 -->
      <div class="scroll-header">
        <div class="title">{{ $t("withdrawal.select") }}</div>
      </div>
      <van-list>
        <van-cell
          @click="changeSk(item)"
          v-for="(item, index) in list"
          :key="index"
        >
          <template #title>
            <div class="demo-content">
              <div class="card-wrap">
                <ul>
                  <li class="cardType">
                    <p class="name">{{ item.name | nameFormet }}</p>
                    <p class="code" v-if="item.rowtype == 2">{{ item.cate }}</p>
                  </li>
                  <li class="number">
                    <!---->
                    <p
                      class="card-number"
                      v-if="!item.show && item.rowtype == 1"
                    >
                      <span class="star">****</span
                      ><span class="star">****</span
                      ><span class="star">****</span
                      ><span>{{ item.bank_card | cardFormet }}</span>
                    </p>
                    <p
                      class="card-number1"
                      v-if="!item.show && item.rowtype == 2"
                    >
                      *****************************************
                    </p>
                    <p
                      class="card-number"
                      v-if="item.show && item.rowtype == 1"
                    >
                      {{ item.bank_card }}
                    </p>
                    <p
                      class="card-number"
                      v-if="item.show && item.rowtype == 2"
                    >
                      {{ item.address }}
                    </p>
                  </li>
                </ul>
                <div class="card-number-icon" @click.stop="showHide(item)">
                  <p
                    class="iconfont"
                    :class="[
                      item.show ? 'icon-yanjing_xianshi' : 'icon-yincang',
                    ]"
                  ></p>
                  <!---->
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
      <div class="btn_warp_bot">
        <p @click="showTypeChoice" class="add-card iconfont icon-tianjia">
          {{ $t("withdrawal.addBtn") }}
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import addTypePopup from "@/components/mc/addTypePopup";
import { getWithdraw, getBilian, getBank, withdrawDo } from "@/api/user";
import { Toast, Dialog } from "vant";
/* import func from "vue-editor-bridge"; */
export default {
  name: "",
  components: { addTypePopup },
  data() {
    return {
			show:false,
      showPopup: false,
      skShow: false,
      list: [],
      value: "",
      checkeZh: null,
      money: "", // 提现金额
      pay_password: "", // 提现密码
      ktmoney: 0,
    };
  },
  filters: {
    cardFormet: function (value) {
      if (!value) return "";
      let str = "";
      if (value) {
        str = value.substring(value.length - 4, value.length);
      }
      return str;
    },
    nameFormet: (value) => {
      let str = value;
      switch (value) {
        case 0:
          str = "比特币";
          break;
        case 1:
          str = "泰达币";
          break;
        case 2:
          str = "以太坊";
          break;
        case 3:
          str = "莱特币";
          break;
        case 4:
          str = "USD Coin";
          break;
        default:
          str = value;
          break;
      }
      console.log();
      return str;
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getWithdraw();
  },
  watch: {
    $route: "getWithdraw",
  },
  methods: {
		moneyShow(){
			if(this.show == false){
				this.show = true
				return
			}else{
				this.show = false
				return
			}
		},
		
    onClickLeft() {
      this.$router.go(-1);
    },
    /* 关闭选择收款账户类型弹出 */
    close() {
      this.showPopup = false;
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t("common.loading"),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    /* 查询提现 */
    getWithdraw() {
      this.loadingCom();
      getWithdraw().then((res) => {
        Toast.clear();
        if (res.data.money) {
          this.ktmoney = res.data.money;
        }
        if (!res.data.pay_password) {
          Dialog.confirm({
            title: this.$t("common.msg"),
            message: this.$t("m_cue.qwcszqtmm"),
            confirmButtonText: this.$t("m_cue.qsz"),
            cancelButtonText: this.$t("m_cue.qx"),
          })
            .then(() => {
              this.$router.push("/usercipherpassword");
            })
            .catch(() => {
              this.$router.go(-1);
            });
        } else if (!res.data.bank && !res.data.bilian) {
          this.showPopup = true;
        } else {
          this.getBankShow();
        }
      });
    },
    /* 获取币链 */
    getBilianShow() {
      getBilian().then((res) => {
        res.data.forEach((item) => {
          item.show = false;
          item.rowtype = 2;
          item.name = item.type;
          this.list.push(item);
        });
      });
    },
    /* 获取银行卡 */
    getBankShow() {
      this.loadingCom();
      getBank().then((res) => {
        res.data.forEach((item) => {
          item.show = false;
          item.rowtype = 1;
          item.name = item.bank;
          this.list.push(item);
        });
        this.checkeZh = this.list[0];
        Toast.clear();
      });
    },
    /* 跳转 */
    change(event) {
      if (event.value === "band") {
        // 添加银行卡
        this.$router.push("/addBand");
      } else if (event.value === "ustd") {
        // 添加银行卡
        this.$router.push("/currency");
      }
      this.showPopup = false;
    },
    /* 选择了收款账户 */
    changeSk(item) {
      this.checkeZh = item;
      console.log(this.checkeZh);
      this.skShow = false;
    },
    /* 打开账户添加类型选择 */
    showTypeChoice() {
      this.showPopup = true;
    },
    /* 打开收款账户弹出 */
    showSkFun() {
      this.list = [];
      this.getBankShow();
      this.getBilianShow();
      this.skShow = true;
    },
    /* 显示隐藏账户 */
    showHide(item) {
      item.show = !item.show;
    },
    /* 开始提现了 */
    withdrawDoFun() {
      console.log(this.checkeZh);
      if (this.money === "") {
        Toast.fail(this.$t("common.addmsg"));
        return;
      }
      if (this.money < 100) {
        Toast.fail(this.$t('withdrawal.zdtxje'));
        return;
      }
      if (this.pay_password === "" || this.pay_password.length < 4) {
        Toast.fail(this.$t("common.addmsg"));
        return;
      }
      if (!this.checkeZh) {
        return;
      }
      let dataObj = {
        money: this.money,
        pay_password: this.pay_password,
      };

      if (this.checkeZh.rowtype + "" === "2") {
        // 碧莲
        dataObj.bilian_id = this.checkeZh.id;
      } else if (this.checkeZh.rowtype + "" === "1") {
        // 银行卡
        dataObj.bank_id = this.checkeZh.id;
      }
      this.loadingCom();
      withdrawDo(dataObj).then((res) => {
        Toast.clear();
        this.money = "";
        this.pay_password = "";
        if (res.code + "" === "201") {
          Toast.fail(this.$t("m_password.mmcw"));
        } else if (res.code + "" === "200") {
          Toast.success(this.$t("m_cue.czcg"));
          setTimeout(() => {
            this.getWithdraw();
          }, 1000);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-cell::after {
  border: none;
}
.van-cell__title {
  color: #9fb0ff;
}
.top-cell .van-cell__title {
  color: #fff;
}

/deep/.van-field__label {
  color: #9fb0ff;
}

/deep/.van-popup__close-icon--top-right {
  top: 0.15rem;
  right: 16px;
}

/deep/.van-popup__close-icon {
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.van-cell-group {
  background-color: #2b2e43;
}

#withdraw {
  width: 100%;
  height: 100%;
  background: #303f70 url(../../../assets/new_img/cp2.png) no-repeat top;
  background-size: cover;

  .head {
    color: #fff;
    text-align: center;
    height: 2.56rem;
    padding-top: 0.53rem;
    background-position: center;
    background-size: 100% 100%;
    box-sizing: border-box;
	position: relative;
	// display: flex;
	// justify-content: center;
	// flex-direction: column;
    .tit {
      font-size: 0.24rem;
      margin-bottom: 0.12rem;
    }

    .money {
      font-size: 0.82rem;
    }
	.heads{
		width: 100%;
		position: absolute;
		// top: 0;
		// left: 0;
		.moneys{
			color: #FFFFFF;
			font-size: 0.625rem;
		}
	}
	
  }
}

.top-cell {
  padding: 0.3rem 0.2rem;
  display: flex;
  align-items: center;
  background-color: #2b2e43;
  margin-bottom: 0.3rem;
}

.cell-title {
  display: flex;
  align-items: center;

  .icon_warp {
    width: 0.8rem;
    height: 0.8rem;
    background-color: #d6a23e;
    border-radius: 0.8rem;
    line-height: 0.8rem;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
    }

    .iconfont {
      color: #fff;
      font-size: 0.4rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .custom-title {
    font-size: 0.28rem;
    margin-left: 0.1rem;
  }

  .custom-title-bot {
    margin-left: 0.1rem;
    color: #9fb0ff;
    font-size: 0.24rem;
  }
}

.btn_warp {
  margin: 0.3rem 0.25rem 0.45rem;

  .txbtn {
    width: 100%;
    /* background-image: linear-gradient(0deg, #ceab77, #eddbc1); */
    background-image: linear-gradient(90deg, #2948ff, #396afc);
    color: #000;
    border: none;
    font-size: 18px;

    .van-button__text {
      color: #ffffff;
    }
  }
}

.description {
  margin: 0px 0.262rem;
  color: #999999;

  .rule-tit {
    font-size: 0.28rem;
    margin-bottom: 0.225rem;

    i {
      color: #d6a23e;
      font-size: 0.44rem;
    }
  }

  .rule-info p {
    margin-bottom: 0.15rem;
    font-size: 0.24rem;
    a {
      color: #9fb0ff;
    }
  }
}

.with_tips {
  width: 100%;
  color: #9fb0ff;
  text-align: center;
  font-size: 0.3rem;
  line-height: 1rem;
}

.popup-sk {
  box-sizing: border-box;
  background-color: #303e70;

  .btn_warp_bot {
    padding: 0.3rem;
    box-sizing: border-box;

    .add-card {
      margin-top: 0.3rem;
      width: 100%;
      font-size: 0.34rem;
      text-align: center;
      color: #2979ff;
      border-radius: 0.1rem;
      height: 0.88rem;
      line-height: 0.88rem;
      border: dashed 1.5px #2979ff;
      box-sizing: border-box;
    }
  }

  .scroll-header {
    height: 40px;
    line-height: 40px;
    /* background-image: linear-gradient(0deg, #ceab77 20%, #eddbc1 100%); */
    background-color: rgb(50, 59, 92) !important;
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-size: 0.3375rem;
  }

  .demo-content {
    .card-wrap {
      width: 100%;
      position: relative;

      .card-number-icon {
        position: absolute;
        right: 0.1rem;
        top: 0.1rem;
        width: 1rem;
        height: 1rem;

        p {
          color: #fff;
          font-size: 0.4rem;
          width: 1rem;
          height: 1rem;
          line-height: 1rem;
          text-align: center;
        }
      }

      ul {
        height: 2.4rem;
        border-radius: 0.2rem;
        color: #fff;
        font-size: 0.34rem;
        padding: 0.3rem;
        background-color: #2b2e43;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .cardType {
          overflow: hidden;
          display: flex;
          align-items: center;
          height: 0.5rem;

          .name {
            font-size: 0.34rem;
            margin-right: 0.2rem;
            color: #9fb0ff;
          }

          .code {
            font-size: 0.28rem;
          }
        }

        .number {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;

          p {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            span {
              flex: 1;
              font-size: 0.34rem;
              line-height: 0.6rem;
              text-align: right;
              padding: 0 0.1rem;
              box-sizing: border-box;
              word-break: break-all;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
}
</style>