<!-- 选择收款账户类型 -->
<template>
  <div class="addTypePopup">
    <div class="wrap">
      <div class="context">
        <h4>{{$t('checkType.title')}}</h4>
        <ul>
          <li @click="jump('band')">
            <span class="iconfont icon-jia"></span>
            {{$t('checkType.tab1')}}
          </li>
          <!-- <li @click="jump('ustd')">
            <span class="iconfont icon-yingbi"></span>
            {{$t('checkType.tab2')}}
          </li> -->
        </ul>
      </div>
      <span class="iconfont icon-guanbi1 close" @click="close"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "addTypePopup",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    /* 跳转 */
    jump(str) {
      this.$emit("change", {
        value: str,
      });
    },
    /* 关闭了弹窗 */
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="less">
.addTypePopup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2036;

  .wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 5.5rem;

    .context {
      width: 100%;
      height: 100%;
      background-color: #f5f5f5;
      border-radius: 0.2rem;
      overflow: hidden;

      h4 {
        /* background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
        background-blend-mode: normal, normal; */
        background-color: #323b5c;
        height: 1rem;
        font-size: 0.32rem;
        line-height: 1rem;
        text-align: center;
        color: #ffffff;
      }

      ul {
        width: 100%;
        padding: 0.3rem 0.45rem;
        box-sizing: border-box;

        li {
          box-sizing: border-box;
          width: 100%;
          height: 1.3rem;
          background-color: #ffffff;
          -webkit-box-shadow: 0px 3px 8px 0px rgb(190 190 190 / 60%);
          box-shadow: 0px 3px 8px 0px rgb(190 190 190 / 60%);
          border-radius: 0.1rem;
          margin-bottom: 0.3rem;
          display: flex;
          align-items: center;
          color: #333333;
          font-size: 0.34rem;
          padding-left: 0.5rem;

          span {
            color: #323b5c;
            margin-right: 0.15rem;
            font-size: 0.34rem;
          }
        }
      }
    }

    .close {
      position: absolute;
      left: 50%;
      bottom: -1rem;
      font-size: 0.5rem;
      width: 1rem;
      height: 1rem;
      color: #fff;
      line-height: 1rem;
      text-align: center;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}
/* @import url(); 引入css类 */
</style>